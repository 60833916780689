@use '../util' as *;

.header {
    margin-top: rem(36);
    @include breakpoint(medium){
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__title{

    }
    &__subtitle{
        font-size: rem(18);
        font-weight: 700;
        color: var(--dark-text1);
    }
    &__toggle{

    }

}