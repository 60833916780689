@use '../util/' as *;


.cards{
    display: grid;
    margin-top: rem(20);
    grid-template-columns: 1fr;
    gap: rem(30);
    @include breakpoint(medium){
        grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(large){
        grid-template-columns: repeat(4, 1fr);
    }
}
.card {
    position: relative;
    overflow: hidden;
    background: var(--dark-card);
    color: var(--dark-text1);
    text-align: center;
    padding: rem(25);
    border-radius: rem(5);
    text-align: center;
    margin-bottom: rem(30);
    transition: background 150ms ease-in-out;
    cursor: pointer;

    &:hover{
        background:var(--dark-card-hover);
    }
    &--facebook{
        border-top: rem(5) solid var(--facebook);
    } 
    &--twitter{
        border-top: rem(5) solid var(--twitter);
    }
    &--instagram{
        padding-top: rem(30);

        &::before {
            content: '';
            height:rem(5);
            width: 100%;
            background: var(--instagram-start);
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            background: linear-gradient(
                225deg, 
                var(--instagram-end) 0%, 
                var(--instagram-middle) 50.91%, 
                var(--instagram-start) 100%);
        }
    }
    &--youtube{
        border-top: rem(5) solid var(--youtube);
    }
    &--grid{

    }
    &__platform{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(8);
        margin-bottom: rem(28);
        height: rem(20);
        margin-top: rem(5);
    }
    &__subtitle{
        font-size: rem(14);
        font-weight: 700;
    }
    &__icon {
        &--facebook{

        }
        &--twitter{
    
        }
        &--instagram{
    
        }
        &--youtube{
    
        }
    }
    &__username{
        font-size: rem(12);
        font-weight: bold;
    }
    &__followers{
        margin-bottom: rem(25);
    }
    &__count{
        color: var(--light-bg);
        font-weight: 700;
        letter-spacing: rem(-2);
        line-height: 1;
        margin-bottom: rem(4);
        &--big{
            font-size: rem(56);

        }
        &--small{
            font-size: rem(32);
        }
    }
    &__label{
        color: var(--dark-text1);
        font-size: rem(12);
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: rem(5);
    }
    &__change{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: rem(4);
        font-size: rem(12);
        font-weight: 700;

        &--up {
            color: var(--limegreen);
        }

        &--down {
            color: var(--brightred);
        }
    }

}